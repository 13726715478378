import React, { useEffect, useState } from "react"
import Header from "../browser/components/Header"
import Footer from "../universal/components/Footer"
import AboutUsPageContent from "../views/AboutUsPageContent"
import Helmet from "react-helmet"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getAboutUs } from "../redux/actions/strapiActions"
import strapi from "../api/strapi"
import Loader from "../universal/components/Loader"
import { showCookieBanner } from "../helpers/functions"
import Cookies from "../universal/components/Cookies"

const AboutUs = ({ getAboutUs, aboutUs }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    })

    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responsePageData = await strapi.get("/onama")
        getAboutUs(responsePageData.data)
        //document.title = "Dalmatiko osiguranje | O nama"
      } catch (err) {
        console.log(err.message)
      }
    }
    fetchData()
  }, [getAboutUs])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dalmatiko | O nama</title>
        <link rel="canonical" href="https://dalmatiko-osiguranje.hr/about-us" />
        <meta
          name="description"
          content="Tko smo i što radimo. Dalamtiko osiguranje tu da vam odgovori na sva pitanja vezana za vaše osiguranje."
        />
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <AboutUsPageContent />
          {showCookieBanner() ? <Cookies /> : null}
          <Footer />
        </>
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    aboutUs: state.strapiReducer.aboutUs,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAboutUs: bindActionCreators(getAboutUs, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs)
