import React from "react"
import AboutUsCities from "../../universal/components/AboutUsCities"
import AboutUsImageText from "../../universal/components/AboutUsImageText"
import AboutUsTitle from "../../universal/components/AboutUsTitle"
import InfoContainer from "../../universal/components/InfoContainer"
import "./index.scss"
import "../root_anims.scss"

const AboutUsPageContent = () => {
  return (
    <div className="about-us-page-container">
      <AboutUsTitle />
      <AboutUsImageText />
      <AboutUsCities />
      <InfoContainer />
    </div>
  )
}

export default AboutUsPageContent
