import React from "react"
import "./index.scss"

import { connect } from "react-redux"

const AboutUsImageText = ({ aboutUs }) => {
  return (
    <div className="about-us-image-text-holder">
      <div className="squad-container">
        <img
          src={`https://api.trebamosiguranje.hr${aboutUs?.Slika.url}`}
          alt="Squad Image"
          className="about-us-image"
          onLoad={e => e.target.classList.add("loaded")}
        />
        <img
          src={`https://api.trebamosiguranje.hr${aboutUs?.Slika.url}`}
          alt="Squad Image2"
          className="about-us-image-blur"
          onLoad={e => e.target.classList.add("loaded")}
        />
      </div>

      <div className="title-text">
        <h2 className="title">{aboutUs?.Opis_2}</h2>

        <p className="text">{aboutUs?.Opis_3}</p>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    aboutUs: state.strapiReducer.aboutUs,
  }
}

export default connect(mapStateToProps, null)(AboutUsImageText)
