import React from "react"
import "./index.scss"

import { connect } from "react-redux"

const AboutUsCities = ({ aboutUs }) => {
  return (
    <div className="about-us-cities-holder">
      <h2 className="about-us-cities-title">{aboutUs?.Prisutnost?.Naslov}</h2>

      <p className="about-us-cities-text">{aboutUs?.Prisutnost?.Opis}</p>

      <ul className="city-list">
        {aboutUs?.Prisutnost?.Lista_Gradova.map(city => (
          <li className="city-item" key={city.id}>
            <span className="bullet">&#8226;</span>
            {city.Grad}
          </li>
        ))}
      </ul>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    aboutUs: state.strapiReducer.aboutUs,
  }
}

export default connect(mapStateToProps, null)(AboutUsCities)
