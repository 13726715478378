import React, { useState, useEffect, useRef, createRef } from "react"
import { useForm } from "react-hook-form"
import strapi from "../../../api/strapi"
import { openModalSentMessage } from "../../../redux/actions/strapiActions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import "./index.scss"
import {
  gaCallClickEvent,
  gaCallInteraction,
} from "../../../helpers/functions/googleAnalytics"
import axios from "axios"

const InfoContainer = ({ openModalSentMessage, isOpenModalSentMessage }) => {
  const [isActiveInputField, setActiveInputField] = useState(0)
  const isLegit = useRef(false)
  const inputRef = useRef(null)
  const clickEventRef = useRef(false)

  const { register, handleSubmit, watch, errors, reset } = useForm()

  let values = watch()

  useEffect(() => {
    if (!isOpenModalSentMessage) document.body.classList.remove("body-scroll")
  }, [isOpenModalSentMessage])

  useEffect(() => {
    return function cleanup() {
      if (inputRef.current) {
        gaCallInteraction("Korisnik odustao")
      }
    }
  }, [])

  const onSubmit = async data => {
    openModalSentMessage()

    const responseZahtjevi = await strapi.post("/zahtjevis", {
      Ime_Prezime: data.firstname_lastname,
      Email: "mail@mail.com",
      Broj_Mobitela: data.mobile,
    })
    console.log(responseZahtjevi)
    reset("firstname_lastname")
    reset("mobile")

    if (responseZahtjevi.status === 200) {
      try {
        const resFuelMail = await axios.post(
          "https://mail.trebamosiguranje.hr/dalmatiko-mailer/v1/send",
          {
            type: "[Zatrazi poziv]",
            name: data.firstname_lastname,
            mobile: data.mobile,
            email: "",
            content: "",
          }
        )
        console.log(resFuelMail)
      } catch (err) {
        console.error("Fuel Mailer Error: ", err.message)
      }
    }

    if (isActiveInputField) {
      gaCallInteraction("Korisnik unio sve podatke")
    }

    switch (window.location.pathname) {
      case "/":
        gaCallInteraction("Korisnik zatrazio poziv sa landing stranice")
        break

      case "/about-us":
        gaCallInteraction("Korisnik zatrazio poziv sa o-nama stranice")
        break

      case "/blog":
        gaCallInteraction("Korisnik zatrazio poziv sa blog stranice")

      default:
        break
    }

    isLegit.current = false
  }

  const validateTelNumber = evt => {
    var theEvent = evt || window.event
    let key
    let keyCode

    if (theEvent.type === "paste") {
      key = evt.clipboardData.getData("text/plain")
    } else {
      key = theEvent.keyCode || theEvent.which

      keyCode = theEvent.keyCode || theEvent.which

      key = String.fromCharCode(key)
    }
    let regex = /([0-9]|[\-+/#])+$/i

    if (keyCode === 13) {
      if (
        /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/i.test(
          theEvent.target.value
        )
      ) {
        console.log("OVDJE SAM")
        handleSubmit(onSubmit)
      }
    } else if (!regex.test(key)) {
      console.log("NA NA NA ")
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    }
  }

  const gaInteraction = () => {
    if (
      (values?.firstname_lastname.length > 2 ||
        /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/i.test(
          values?.mobile
        )) &&
      !isLegit.current
    ) {
      gaCallInteraction("Prvi podatak unesen")
      isLegit.current = true
    }

    if (
      values?.firstname_lastname.length > 2 &&
      /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/i.test(
        values?.mobile
      ) &&
      isLegit.current
    ) {
      gaCallInteraction("Korisnik unio sve podatke")
    }
  }

  const gaClickEvent = () => {
    switch (window.location.pathname) {
      case "/":
        gaCallClickEvent("Fokus na zatrazi poziv sa landing stranice")
        break

      case "/blog":
        gaCallClickEvent("Fokus na zatrazi poziv sa blog stranice")
        break

      case "/o-nama":
        gaCallClickEvent("Fokus na zatrazi poziv sa o-nama stranice")

        break

      default:
        break
    }
    clickEventRef.current = true
  }

  const handleOnChange = e => {
    if (e.target.value.length === 0) {
      isLegit.current = false
    }

    inputRef.current = e.target.value
  }

  return (
    <>
      <div className="info-container-holder">
        <div className="info-text-container">
          <h2 className="info-title">Trebaš više informacija?</h2>

          <p className="info-desc">
            Svaki dan smo dostupni za tvoja pitanja. Ostavi nam svoje podatke i zovemo te jako brzo.
          </p>
        </div>

        <div className="contact-form-container">
          <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <h3 className="contact-title">Zatraži poziv</h3>
            <label
              htmlFor="firstname_lastname"
              className={`name-label ${
                isActiveInputField === 1 ||
                values.firstname_lastname?.length > 0
                  ? "active"
                  : ""
              } ${errors.firstname_lastname ? "error" : ""}`}
            >
              Ime i prezime
            </label>
            <input
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
                minLength: {
                  value: 2,
                  message: "Min name lenght is 2",
                },
              })}
              id="firstname_lastname"
              name="firstname_lastname"
              type="text"
              className={`contact-firstname-lastname ${
                isActiveInputField === 1 ? "active" : ""
              } ${errors.firstname_lastname ? "error" : ""}`}
              onFocus={() => {
                setActiveInputField(1)
                !clickEventRef.current && gaClickEvent()
              }}
              onClick={() => setActiveInputField(1)}
              onBlur={() => {
                setActiveInputField(0)
                gaInteraction()
              }}
              onChange={e => handleOnChange(e)}
            />
            <label
              htmlFor="mobile"
              className={`contact-label ${
                isActiveInputField === 2 || values.mobile?.length > 0
                  ? "active"
                  : ""
              } ${errors.mobile ? "error" : ""}`}
            >
              Broj mobitela
            </label>
            <input
              name="mobile"
              type="tel"
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
                pattern: {
                  value: /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/i,
                  message: "Invalid mobile number",
                },
              })}
              onKeyPress={e => validateTelNumber(e)}
              id="mobile"
              className={`contact-mobile ${
                isActiveInputField === 2 ? "active" : ""
              } ${errors.mobile ? "error" : ""}`}
              onFocus={() => {
                setActiveInputField(2)
                !clickEventRef.current && gaClickEvent()
              }}
              onClick={() => setActiveInputField(2)}
              onBlur={() => {
                setActiveInputField(0)
                gaInteraction()
              }}
              onChange={e => handleOnChange(e)}
            />
            <div className="submit-btn-container">
              <button
                type="submit"
                className="contact-btn"
                // onClick={() =>
                //   values.firstname_lastname &&
                //   values.firstname_lastname !== "" &&
                //   values.mobile !== "" &&
                //   Object.keys(errors).length === 0 &&
                //   openModalSentMessage()
                // }
                style={{ cursor: "pointer" }}
              >
                Zatraži poziv
              </button>
            </div>
            <p className="contact-desc">
              Klikom na “Zatraži poziv” potvrđuješ da si pročitao/la uvjete korištenja i informacije o zaštiti osobnih podataka. Bez brige, čuvamo tvoju privatnost i podatke.
            </p>
          </form>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpenModalSentMessage: state.strapiReducer.isOpenModalSentMessage,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModalSentMessage: bindActionCreators(openModalSentMessage, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoContainer)
