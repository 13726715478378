import React from "react"
import "./index.scss"

import { connect } from "react-redux"

const AboutUsTitle = ({ aboutUs }) => {
  return (
    <>
      <h5 className="about-us-title1">{aboutUs?.Naslov}</h5>

      <h3 className="about-us-title2">{aboutUs?.Naslov_2}</h3>

      <div className="about-us-info">
        <div className="experts-experiances">
          <div className="experts">
            <h4 className="experts-year">{aboutUs?.Strucnjaci}</h4>
            <p className="experts-text">strucnjka</p>
          </div>

          <div className="experiances">
            <h4 className="experiances-year">{aboutUs?.Godine_Iskustva}</h4>
            <p className="experiances-text">godina iskustva</p>
          </div>
        </div>

        <p className="about-us-who">{aboutUs?.Opis}</p>
      </div>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    aboutUs: state.strapiReducer.aboutUs,
  }
}

export default connect(mapStateToProps, null)(AboutUsTitle)
